import config from "../../config"
import { authHeader } from "../helpers/auth_header"

export default class recordService {
  getAll() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records`, requestOptions).then(
      handleResponse
    )
  }

  getAangespoeld() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(
      `${config.iwib.server}/records/aangespoeld`,
      requestOptions
    ).then(handleResponse)
  }

  getTjalie() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/tjalie`, requestOptions).then(
      handleResponse
    )
  }

  getTjaliePoints() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/tjaliepoints`, requestOptions).then(
      handleResponse
    )
  }
  getTjaliePoints_Admin() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/tjaliepoints_admin`, requestOptions).then(
      handleResponse
    )
  }


  getPiekirpedia() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/piekirpedia`, requestOptions).then(
      handleResponse
    )
  }

  getPiekirPoints() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/piekirpoints`, requestOptions).then(
      handleResponse
    )
  }
  getPiekirPoints_Admin() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/piekirpoints_admin`, requestOptions).then(
      handleResponse
    )
  }

  getEternite() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/eternite`, requestOptions).then(
      handleResponse
    )
  }

  getById(id) {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/${id}`, requestOptions).then(
      handleResponse
    )
  }

  getBySlug(slug, type) {
    if (type == 5) return this.search(`uniqid=${slug}`, 1, 1)
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(
      `${config.iwib.server}/records/${type}/${slug}`,
      requestOptions
    ).then(handleResponse)
  }

  update(record) {
    const requestOptions = {
      method: "PUT",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(record),
    }

    return fetch(
      `${config.iwib.server}/records/${record.id}`,
      requestOptions
    ).then(handleResponse)
  }

  add(record) {
    const requestOptions = {
      method: "POST",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(record),
    }

    return fetch(`${config.iwib.server}/records/add`, requestOptions).then(
      handleResponse
    )
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/records/${id}`, requestOptions).then(
      handleResponse
    )
  }

  upload(file) {
    let formData = new FormData()
    formData.append("mediaFile", file)
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: formData,
    }

    return fetch(`${config.iwib.server}/records/upload`, requestOptions).then(
      handleResponse
    )
  }

  search(query, start, nrec) {
    const requestOptions = {
      method: "GET",
    }

    return timeout(
      5000,
      fetch(
        `${config.iwib.server}/cc/${start}/${nrec}/${query}`,
        requestOptions
      ).then(handleResponse)
    )
  }
}

function timeout(ms, promise) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error("TIMEOUT"))
    }, ms)

    promise
      .then((value) => {
        clearTimeout(timer)
        resolve(value)
      })
      .catch((reason) => {
        clearTimeout(timer)
        reject(reason)
      })
  })
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
